import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import Gift from './components/Gift';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Check if the ElevenLabs script is already added
    if (!document.querySelector('script[src="https://elevenlabs.io/convai-widget/index.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://elevenlabs.io/convai-widget/index.js';
      script.async = true;
      script.type = 'text/javascript';
      document.body.appendChild(script);
    }
  }, []);

  return (
    <Router>
      <div className="App h-screen flex flex-col">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gift" element={<Gift />} />
          <Route path="/gift/:giftName" element={<Gift />} />
        </Routes>
        {/* Add the widget globally */}
        <elevenlabs-convai agent-id="ZAiSfR2uFVDJoCPMp4uh"></elevenlabs-convai>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
