import React from 'react';

function Footer() {
  const footerPhrases = [
    "Oh good, you've reached the bottom.",
    "What do people put in website footers nowadays?",
    "That's all, folks!",
    "Footer? I hardly know 'er!",
    "© 2024 by Dalmo",
    "Bless up!",
    "Scrolling all the way deserves a medal!",
    "You found the treasure at the end of the scroll.",
    "Need a footer? Call Dalmo.",
    "Endings are just new beginnings. Except here.",
    "You made it to the footer! High five!",
    "All roads lead to this footer."
  ];

  const randomPhrase = footerPhrases[Math.floor(Math.random() * footerPhrases.length)];

  return (
    <footer className="bg-black text-white p-2 text-center bottom-0 w-full">
      {randomPhrase}
    </footer>
  );
}

export default Footer;
