const giftsData = [
    {
        id: 1,
        title: 'Guided Meditations',
        summary: 'Sit back, relax, and follow along to these grounding and awakening meditations.',
        icon: '../images/meditation-guidance.png',
        links: [
            {
                title: 'I Am the Vine',
                url: 'https://www.youtube.com/watch?v=zhsOjSyUmnQ'},
            {
                title: 'Whole Body Mystical Awakening',
                url: 'https://youtu.be/mKLGxSlhpRk?si=_hKytFKAuI1nUgN6'},
            {
                title: 'Whole Body Mystical Awakening (WeSpace)',
                url: 'https://youtu.be/LwhRPbwgt4A?si=SQGnUP6SPcBvdHeY'},
            {
                title: 'Receiving',
                url: 'https://open.spotify.com/episode/63oUgfocZpZA7VE5Vlgbya?si=468a7bd8bd1747e2'},
            {
                title: 'Romans 8',
                url: 'https://open.spotify.com/episode/2njDtLa7norVgKLCq67CIC?si=7cd1ca2fbbf34002'},
            {
                title: 'Sacred Breath',
                url: 'https://open.spotify.com/episode/3Dn0Zh74PKOtiVqXcSV0XM?si=cce08a89ba874681'},
            {
                title: 'Listening to the Symphony',
                url: 'https://open.spotify.com/episode/2pFPMFpLQEWZp6NaoBspAf?si=093e16636c0c47d6'},
            {
                title: 'Praying with the Mirror',
                url: 'https://open.spotify.com/episode/6kubQbuUF0tNEUxMeQymAu?si=654de2523a5e459b'}
        ]
        },
    {
        id: 2,
        title: 'AI Art',
        summary: 'Browse my curated collection of AI-generated art.',
        icon: '../images/ai-art.png',
        albums: [
            {
                title: 'Mystical Activism',
                url: '../images/AI Art/Mystical Activism',
                images: [
                    'Angelic Protection.png',
                    'Christ Is The Way.png',
                    'Circling Impressions.png',
                    'Circling with Love.png',
                    'Co-Creation.png',
                    'Collective Intention.png',
                    'Cosmic Energy.png',
                    'Dance the Cosmic Dance.png',
                    'Dawn of Awareness II.png',
                    'Dawn of Awareness.png',
                    'Embodied Unity.png',
                    'Enchanted Inner Forest.png',
                    'Father, The Hour Is Come.png',
                    'Flip the Tables.png',
                    'Global Consciousness.png',
                    'Global Culture Network.png',
                    'God Frequencies.png',
                    'He Mourns with Us.png',
                    'Heaven and Earth.png',
                    'Here, There & Everywhere.png',
                    'In and Through Me.png',
                    'Interconnection.png',
                    'Jesus Weeps.png',
                    'Let the Sun Shine In.png',
                    'Light in the Darkness.png',
                    'Lights of the World.png',
                    'Modern Monks.png',
                    'Mount of Transfiguration.png',
                    'Mystical Awakening.png',
                    'Mystical Prayer.png',
                    'On Earth As It Is In Heaven.png',
                    'Open Heavens.png',
                    'Pray for the City.png',
                    'Pray for the World.png',
                    'Prayer Energy Swirl.png',
                    'Prayer in Waves.png',
                    'Prayer is a Flame.png',
                    'Prayer Transforms the Universe.png',
                    'Prayer Vibes.png',
                    'Ripple Effect.png',
                    'Rooted Prayer.png',
                    'Sending Metta.png',
                    'Serene Meditation.png',
                    'Social Activist Jesus.png',
                    'Spiral of Peace.png',
                    'The Angelus.png',
                    'Transfiguration.png',
                    'Urban Monks.png',
                    'Visionary Christ.png',
                    'WeSpace Impressions.png'
                ]},
            {
                title: 'Divine Feminine',
                url: '../images/AI Art/Divine Feminine',
                images: [
                    'Action and Contemplation.png',
                    'Alabaster Devotion.png',
                    'Deborah.png',
                    'Desert Rose 2.png',
                    'Desert Rose.png',
                    'Dorcas and the Widows.png',
                    'Dreamweaver.png',
                    'Glossolalia.png',
                    'Hagar\'s Prayer.png',
                    'Like Miriam Danced.png',
                    'Mary and Martha.png',
                    'Patchwork Womanhood.png',
                    'Peace Anthem.png',
                    'Peace, Be Still.png',
                    'Phoebe, Priscilla, and Junia.png',
                    'Prophetess.png',
                    'Psalm 131.png',
                    'Sacred Womb.png',
                    'Sing Praise.png',
                    'Teacher Sophia.png',
                    'Women of Pentecost.png'
                ]},
            {
                title: 'Awakened Abilities',
                url: '../images/AI Art/Awakened Abilities',
                images: [
                    'A Network of Love.png',
                    'Building Together.png',
                    'Co-Laboring.png',
                    'Cosmic Scale.png',
                    'Courage.png',
                    'Creative Ability.png',
                    'Deep Listening.png',
                    'Direct Prayer.png',
                    'Fruits of the Spirit.png',
                    'Gatherer.png',
                    'Gifts and Fruits.png',
                    'Gifts from Heaven.png',
                    'Glossolalia in the City.png',
                    'Golden Shadow.png',
                    'Gratitude and Love.png',
                    'Helper 2.png',
                    'Helper.png',
                    'Intercession.png',
                    'Intuition.png',
                    'Leadership.png',
                    'Let Me Listen.png',
                    'Mystical Whispers.png',
                    'Open Hands.png',
                    'Overflowing Creativity.png',
                    'Quiet Contemplation.png',
                    'Resonant Heart.png',
                    'Resonant Prophecy.png',
                    'Sculpting the Future.png',
                    'Shaping the Future.png',
                    'Sophia.png',
                    'Soundwaves.png',
                    'Spiritual Potential.png',
                    'Stillness.png',
                    'The Jesus Way.png',
                    'To the Crazy Ones.png',
                    'Transmission.png',
                    'Visions of a Hopeful Future.png'
                ]}
        ]
    },
    {
        id: 3,
        title: 'Essays & Blog Posts',
        summary: 'Some of my most popular content',
        icon: '../images/essays.png',
        links: [
            {
                title: 'Walt Whitman: Making Emerson’s “Nature” Come Alive',
                url: 'https://genius.com/Genius-walt-whitman-making-emersons-nature-come-alive-annotated'}
        ]
    },
    {
        id: 4,
        title: 'Poems',
        summary: 'Some poems I like.',
        icon: '../images/poetry-writing.png',
        links: [
            {
                title: 'Last Lament',
                url: 'http://lastlament.com'},
            {
                title: 'Poets & Saints Magazine',
                url: '../files/Poets&Saints-01-StreamsInTheDesert.pdf'},
            {
                title: 'how to eat from gold plates',
                url: 'https://www.instagram.com/p/C2Rb8cCO0Mv/?img_index=1'},
            {
                title: 'Jump In',
                url: 'https://www.instagram.com/p/CymL5sVsk0L/'},
            {
                title: 'Santorini / Awe',
                url: 'https://www.instagram.com/p/CnOLW21O3ER/?img_index=1'},
            {
                title: 'Lines on a Leaf',
                url: 'https://genius.com/Dalmo-mendonca-lines-on-a-leaf-annotated'}
        ]
    },
    {
        id: 5,
        title: 'Playlists',
        summary: 'A curated playlist for every vibe',
        icon: '../images/music-curation.png',
        links: [
            {
                title: 'Have a Nice Trip',
                url: 'https://open.spotify.com/playlist/0xKtGHUsihPg56rZ6VBc2d?si=701dbaf4adc144cb'},
            {
                title: 'Turn On, Tune In, Drop Out',
                url: 'https://open.spotify.com/playlist/2wF6CWhBjHgeU35seGIWEk?si=fe02bd1475ef4eda'},
            {
                title: 'Sativibes',
                url: 'https://open.spotify.com/playlist/0acLmgMstpKCiw5a7ByWha?si=32b2a0ae48464719'},
            {
                title: 'Cover to Cover',
                url: 'https://open.spotify.com/playlist/6lHXraEXlLpVRD3nyUVsl9?si=284849dbbc77446c'},
            {
                title: 'Good Vibes Only',
                url: 'https://open.spotify.com/playlist/6DLYUOH8IVFI1Uk1eXNNMs?si=322158475f7743f4'},
            {
                title: 'Shadow',
                url: 'https://open.spotify.com/playlist/60q7RfHWoxKQ5bNWPl3kmd?si=c1a8e0996fd54254'},
            {
                title: 'Light',
                url: 'https://open.spotify.com/playlist/317rmwO3IMVgAbpPlCQgXL?si=bf41c25fc25f4cd9'},
            {
                title: 'Ugly Crying',
                url: 'https://open.spotify.com/playlist/7k8V6SH0sbkeNssPlDdMFe?si=d6bc2e3a4c3a4f0c'},
            {
                title: 'Musica da Imparare',
                url: 'https://open.spotify.com/playlist/7k8V6SH0sbkeNssPlDdMFe?si=58f46b93bd8b42ec'},
            {
                title: 'God in Focus',
                url: 'https://open.spotify.com/playlist/7CBcybMOygfPnYK97eQ5hU?si=020e1b6b04bd430a'},
            {
                title: 'Order My Steps',
                url: 'https://open.spotify.com/playlist/6gqNzZFr9L715euGsvRDCy?si=f2ef43fce6044664'},
            {
                title: 'Beija-Flor',
                url: 'https://open.spotify.com/playlist/3uG6zT80sH2NDatZaxnpyb?si=dead075faba544da'},
            {
                title: 'Being in Love',
                url: 'https://open.spotify.com/playlist/7DlDc0fGrsN5V1EGmwQLtw?si=9dfc1b6d5f174f78'},
            {
                title: 'Mantras',
                url: 'https://open.spotify.com/playlist/3mYDWCm1lhtgoLZbSAWE8p?si=3eb5b3aafc264834'},
            {
                title: 'Surrender',
                url: 'https://open.spotify.com/playlist/2QAtYSxZuwfaWs7wc5Je3U?si=597ddd81535f40c4'},
            {
                title: 'Powerful Love',
                url: 'https://open.spotify.com/playlist/6BCSzAh5VHd9vayRjP4473?si=5ecb9a9d73e348a0'},
            {
                title: 'Ministry',
                url: 'https://open.spotify.com/playlist/7nG6MoHKSi7zQB2QWfaNwW?si=9bceeef8317b47cd'},
            {
                title: 'Lofibes',
                url: 'https://open.spotify.com/playlist/3XdAjRyStSDIbFMm15iFVy?si=c4713dc9f0074181'},
            {
                title: 'I Miss the Old Kanye',
                url: 'https://open.spotify.com/playlist/764JNkFp3W3Q92ZgDnd2So?si=94500a637c0544b1'},
            {
                title: 'Queer Tears 4 Jesus',
                url: 'https://open.spotify.com/playlist/6qcbmNlADJnKwfmiCO38ci?si=500b62923ffa49d5'},
            {
                title: 'Now I Will Destroy the Whole Wolrd',
                url: 'https://open.spotify.com/playlist/4NRbvQG1gzcmDtqBrh9I77?si=a5428bc215ec40e9'},
            {
                title: 'Repentance',
                url: 'https://open.spotify.com/playlist/3ttBASImDMMrhVXQlr8VAf?si=666b9bab9c664ecb'},
            {
                title: 'Do Brasil',
                url: 'https://open.spotify.com/playlist/29Kcvjf0cfomYKPnkYgPXl?si=fb68bc307eeb4b6a'},
    ]
    },
    {
        id: 6,
        title: 'Apps',
        summary: 'Fun little apps I\'ve coded',
        icon: '../images/website-design.png',
        links: [
            {
                title: 'Claim Samaritan',
                url: 'https://claimsamaritan.com'},
            {
                title: 'PrayGPT',
                url: 'https://praygpt.netlify.app'},
                {
                    title: 'Wordsworthy',
                    url: 'https://wordsworthy.vercel.app/'},
            {
                title: 'Landmarkle',
                url: 'https://landmarkle.com'},
            {
                title: 'Puss Grid',
                url: 'http://pussgrid.com'},
            {
                title: 'BreatheGPT',
                url: '/apps/BreatheGPT/index.html'},
            {
                title: 'BookGPT',
                url: '/apps/BookGPT/index.html'},
            {
                title: 'TaleGPT 🚧 In Progress',
                url: '/apps/TaleGPT/index.html'},
            {
                title: 'Twilight Trivia',
                url: '/apps/twilight/index.html'},
            {
                title: 'Code Room',
                url: 'https://coderoom.ai'},
        ]
    }
];

export default giftsData;
